import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RecommendationService } from '../services/recommendation.service';
import { RouterParamService } from 'src/app/shared/params/params.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Recommendation } from '../models/recommendation.model';

@Component({
  selector: 'app-recommendation-element',
  templateUrl: './recommendation-element.component.html',
  styleUrls: ['./recommendation-element.component.css'],
})
export class RecommendationElementComponent {
  @Input() recommendation: Recommendation;
  @Input() isocode: string;
  constructor() {}
  toUrl() {
    window.open(this.recommendation.url+"?utm_source=cupo&utm_order="+this.recommendation.number, '_blank');
  }
}
