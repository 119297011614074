import {
  AfterContentInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RecommendationService } from '../services/recommendation.service';
import { RouterParamService } from 'src/app/shared/params/params.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Recommendation } from '../models/recommendation.model';
import { FeatureService } from 'src/app/shared/modules/feature/feature.service';
import { NgbCarousel, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, single } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css'],
})
export class RecommendationComponent implements AfterContentInit {
  correlationId: string | null = '';
  isocode: string = '';
  loading: boolean = false;
  cluster: string = '';
  languageCode: string;
  countryCode: string;
  recommendations: Recommendation[] = [];
  @ViewChild('carousel') carousel: NgbCarousel;
  posIni: any;
  constructor(
    public recommendationService: RecommendationService,
    public params: RouterParamService,
    public feature: FeatureService,
  ) {}
  async ngAfterContentInit() {
    this.loading = true;
    this.isocode = this.params.paramsSnapshot()['isocode'];
    this.languageCode = this.isocode.split('-')[0];
    this.countryCode = this.isocode.split('-')[1];
    try {
      await this.feature.use(this.countryCode);
    } catch (x) {
      window.location.href = environment.notFoundUrl;
    }
    let activated = this.feature.activated('feature.recommendations');
    if (activated) {
      this.cluster = this.feature.activated('cluster');
      let response = await this.recommendationService.getRecommendations(
        this.isocode,
        this.cluster,
      );
      if (response instanceof HttpErrorResponse) {
        this.correlationId = response.headers.get('X-Correlation-Id');
        console.error(response);
        this.loading = false;
      } else {
        let result: any = response;
        if (result != undefined) {
          let i: number = 0;
          for (let singleResult of result) {
            i++;
            let recommendation: Recommendation = singleResult;
            recommendation.number = i;
            this.recommendations.push(recommendation);
          }
          this.recommendations = result;
        }
      }
    }
    this.loading = false;
  }

  move(pos: number) {
    const offset = this.posIni - pos;
    if (offset < -100) this.carousel.prev();

    if (offset > 100) this.carousel.next();
  }
}
